import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Silent, Tuple } from "@/__main__/data-model.mjs";

const buildModel = Tuple([
  String, // [0] gameMode
  String, // [1] matchMode
  String, // [2] patch
  String, // [3] hero_id
  String, // [4] item_id
  String, // [5] tier
  String, // [6] num_matches
  String, // [7] num_wins
  String, // [8] num_player_matches
  String, // [9] win_rate
  String, // [10] avg_inventory_index
  String, // [11] hero_total_num_match_players
  String, // [12] hero_tier_total_num_match_players
  String, // [13] item_pick_rate
  String, // [14] item_tier_pick_rate
  Silent(String), // [15] imbued_ability_stats
]) satisfies ModelDefinition;

const heroTransformedModel = {
  gameMode: String,
  matchMode: String,
  patch: String,
  heroId: Number,
  item_id: Number,
  tier: Number,
  num_matches: Number,
  num_wins: Number,
  num_player_matches: Number,
  win_rate: Number,
  avg_inventory_index: Number,
  hero_total_num_match_players: Number,
  hero_tier_total_num_match_players: Number,
  item_pick_rate: Number,
  item_tier_pick_rate: Number,
  imbued_ability_stats: [
    {
      imbued_ability_id: Number,
      imbued_ability_rank: Number,
      num_matches: Number,
      num_player_matches: Number,
      num_player_wins: Number,
      pick_rate: Number,
      win_rate: Number,
    },
  ],
} satisfies ModelDefinition;

export type DeadlockHeroItemStats = FromModel<typeof heroTransformedModel>;

const HeroItemStats = createModel({
  data: {
    executeDatabricksQuery: {
      payload: {
        result: {
          chunkIndex: Number,
          dataArray: [buildModel],
          rowCount: Number,
          rowOffset: Number,
        },
      },
    },
  },
}).transform([heroTransformedModel], (data) => {
  const {
    data: { executeDatabricksQuery },
  } = data;

  const heroes = executeDatabricksQuery?.payload?.result?.dataArray || [];

  const transformedHeroes = heroes
    .filter((hero) => hero[3] && hero[3] !== "0") // has heroId
    .map<DeadlockHeroItemStats>((hero) => {
      return {
        gameMode: hero[0],
        matchMode: hero[1],
        patch: hero[2],
        heroId: parseFloat(hero[3]),
        item_id: parseFloat(hero[4]),
        tier: parseFloat(hero[5]),
        num_matches: parseFloat(hero[6]),
        num_wins: parseFloat(hero[7]),
        num_player_matches: parseFloat(hero[8]),
        win_rate: parseFloat(hero[9]),
        avg_inventory_index: parseFloat(hero[10]),
        hero_total_num_match_players: parseFloat(hero[11]),
        hero_tier_total_num_match_players: parseFloat(hero[12]),
        item_pick_rate: parseFloat(hero[13]),
        item_tier_pick_rate: parseFloat(hero[14]),
        imbued_ability_stats: parseArrayStr(hero[15]),
      };
    });

  return transformedHeroes.filter((h) => h.heroId);
});

export default HeroItemStats;

export type HeroItemStats = {
  gameMode: string;
  matchMode: string;
  patch: string;
  heroId: number;
  item_id: number;
  tier: number;
  num_matches: number;
  num_wins: number;
  num_player_matches: number;
  win_rate: number;
  avg_inventory_index: number;
  hero_total_num_match_players: number;
  hero_tier_total_num_match_players: number;
  item_pick_rate: number;
  item_tier_pick_rate: number;
  imbued_ability_stats: {
    imbued_ability_id: number;
    imbued_ability_rank: number;
    num_matches: number;
    num_player_matches: number;
    num_player_wins: number;
    pick_rate: number;
    win_rate: number;
  }[];
};

function parseArrayStr(val = "") {
  if (!val || !val.startsWith("[")) return [];
  const arr = JSON.parse(val) || [];
  for (const [i, item] of arr.entries()) {
    const d = {};
    for (const key in item) {
      d[key] = parseFloat(item[key]);
    }
    arr[i] = d;
  }
  return arr;
}
